<template>
<div class="market-show" v-if="isshow">
    <div class="show-content">
        <div class="show-content-title">{{info.title}}</div>
        <div class="show-content-user">
            <img class="show-content-user-avatar" v-if="info.user_profile.wechat" :src=" info && info.user_profile && info.user_profile.wechat && info.user_profile.wechat.avatar"/>
            <div class="show-content-user-t">
                <div class="show-content-user-t-name vertical-center">{{info.user_profile && info.user_profile.wechat && info.user_profile.wechat.nickname}}
                  <img src="~assets/images/public/vip1.png" v-if="info.user_profile && info.user_profile.user && info.user_profile.user.level == 'vip1'" class="vipimg">
                  <img src="~assets/images/public/captain.png" v-if="info.user_profile && info.user_profile.is_team_master "  class="captain" />
                  <img src="~assets/images/public/captain-s.png" v-if="info.user_profile && info.user_profile.is_group_team_master "  class="captain-s" />
                  <img :src="info.user_profile.user_label.picture" v-if="info.user_profile && info.user_profile.user_label && info.user_profile.user_label.picture" class="labelimg">
                  <img src="~assets/images/public/36ce.png" v-else class="labelimg">
                </div>
                <div class="show-content-user-t-info">
                    <div class="show-content-user-t-info-l">
                        {{info.user_profile && info.user_profile.team && info.user_profile.team.name}} | 编号: {{info.user_profile && info.user_profile.number}}
                    </div>
                    <div class="show-content-user-t-info-r">
                        {{info.create_time}} | 浏览{{$handle.tranNumber( info.browse_num,3)}}
                    </div>
                </div>
            </div>
        </div>
        <div class="show-content-brief">
            {{info.content}}
        </div>
        <img class="show-content-img"  :src="info.picture[0]" v-if="info.picture[0]"/>
        <img class="show-content-img-s"  v-for="(img,index) in info.picture.slice(1)" :key="index" :src="img"  />
    </div>

<div class="foot">
    <div class="footer">
        <div class="footer-l">
            <div class="footer-l-t  vertical-center">
                <div class="footer-l-t-l"><span>{{info.credit}}</span>积分</div>

                <div class="footer-l-t-r" v-if="info.is_free_shipping">包邮</div>
                <div class="footer-l-t-n" v-if="!info.is_free_shipping">运费:<span>￥{{info.postage}}</span></div>
            </div>
            <div class="footer-l-b vertical-center">余额：{{userInfo && userInfo.credit ? $handle.tranNumber( userInfo.credit,3): 0}}积分</div>
        </div>
        <div class="footer-c">
            <div class="footer-c-item collect"   @click="collect(info.id)">
                <img v-if="info.is_favorite == 0" src="~assets/images/index/star.png"  class="footer-c-img" />
                <img v-if="info.is_favorite == 1" src="~assets/images/index/star-a.png"  class="footer-c-img"/>
                <span>{{info.collect_num}}</span>
            </div>
            <div class="footer-c-item" @click="goNews()">
                <img src="~assets/images/index/talk.png"  class="footer-c-img"/>
                <span>私信</span>
            </div>
        </div>
        <div :class="info.sales >= info.stock || info.status != 1 ? 'footer-r footer-r-no':'footer-r'" @click="buy()" >{{info.sales >= info.stock ? '库存不足': info.status == -1 ? '已违规':'立即兑换'}}</div>
    </div>
    <div class="safety-height"></div>
</div>
    
    <div class="btm-box"></div>
    <van-dialog id="van-dialog" />
    <van-toast id="van-toast" />
</div>
 <van-loading size="24px" v-else class="align-center">加载中...</van-loading>
</template>

<script>
import UserInfo from "components/userInfo";
export default {
  name: "community.market-show",
  data(){
    return{
        id:'',
        info:{},
        userInfo:{},
        isshow:false
    }
  },

    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
          }
          if(this.$route.query.back == 'list'){
            this.$route.meta.backUrl = '/community';
          }
        this.$store.commit('M_ACT','1')
        this.show()
          
  },
  methods:{
    show(){
      if(!this.$cache.getIsLogin()) {
let url = this.$api.Flea   + '/' + this.id 
        this.$http.get(url, false).then(res => {
          if(res.data.success){
            this.isshow = true
             this.info=res.data.data
              if(res.data.data == ''){
                    this.$store.dispatch('A_ERROR_TEXT',{
                        code_num: '602',
                        error_text: '该商品已下架或已关闭'
                    })
                    setTimeout(()=>{
                        this.$router.push({path: '/error'})
                    },500)
              }else if(res.data.data.status == 0 || res.data.data.status == 2){
                  if(this.$store.getters.userInfo.id != res.data.data.user_id){
                    this.$store.dispatch('A_ERROR_TEXT',{
                        code_num: '602',
                        error_text: '该商品已下架或已关闭'
                    })
                    setTimeout(()=>{
                        this.$router.push({path: '/error'})
                    },500)
                  }  
              }else if(res.data.data.status == -1){
                    this.$store.dispatch('A_ERROR_TEXT',{
                        code_num: '602',
                        error_text: '该商品已下架或已关闭'
                    })
                    setTimeout(()=>{
                        this.$router.push({path: '/error'})
                    },500)
              }
              
               
          }
        })
      }else{
        let url = this.$api.Flea   + '/' + this.id 
        this.$http.get(url, true).then(res => {
          if(res.data.success){
            this.isshow = true
             this.info=res.data.data
              if(res.data.data == ''){
                    this.$store.dispatch('A_ERROR_TEXT',{
                        code_num: '602',
                        error_text: '该商品已下架或已关闭'
                    })
                    setTimeout(()=>{
                        this.$router.push({path: '/error'})
                    },500)
              }else if(res.data.data.status == 0 || res.data.data.status == 2 || res.data.data.status == -1){
                  if(this.$store.getters.userInfo.id != res.data.data.user_id){
                    this.$store.dispatch('A_ERROR_TEXT',{
                        code_num: '602',
                        error_text: '该商品已下架或已关闭'
                    })
                    setTimeout(()=>{
                        this.$router.push({path: '/error'})
                    },500)
                  }  
              }
              // else if(res.data.data.status == -1){
              //       this.$store.dispatch('A_ERROR_TEXT',{
              //           code_num: '602',
              //           error_text: '该商品已下架或已关闭'
              //       })
              //       setTimeout(()=>{
              //           this.$router.push({path: '/error'})
              //       },500)
              // }
              
               
          }
        })
      }
        
    },
    //收藏
    collect(id){
       if(!this.$cache.getIsLogin()) {
            this.$store.commit('M_LOGIN_POP', true)
            return
          }
        let url = this.$api.communityCollect
        let data = {
          model_id:id,
          model_type:'flea'
        }
        this.$http.post(url, data, true).then(res => {
          if(res.data.success){
            this.show()
          }
        })
      },
      //兑换
      buy(){
         if(!this.$cache.getIsLogin()) {
            this.$store.commit('M_LOGIN_POP', true)
            return
          }
        let _this = this
        if(this.info.sales >= this.info.stock || this.info.status != 1){
          return
        }
        this.$dialog.confirm({
          title: '兑换',
          message: '是否兑换该商品',
        })
          .then(() => {
            this.$router.push({path: '/order/confirm',query: {f: _this.info.id,type:'flea'}})
          })
          .catch(() => {
            // on cancel
          });
      },
          //跳转消息
 goNews(){
    if(!this.$cache.getIsLogin()) {
            this.$store.commit('M_LOGIN_POP', true)
            return
          }
          this.$router.push('/user/im')
        },
 
  },
  components:{
    UserInfo,
  },
   computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/community/market-show.scss";
.vipimg{
  width: 28/$r;
  height: 25/$r;
  margin-left: 7/$r;
}
.captain{
  width: 77/$r;
  height: 29/$r;
  margin-left: 7/$r;
}
.captain-s{
  width: 91/$r;
  height: 26/$r;
  margin-left: 7/$r;
}
</style>