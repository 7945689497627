<template>
  <div>
    <div class="userinfo vertical-center">
      <img class="userinfo-img"  :src="userinfo && userinfo.avatar ? userinfo.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'">
      <div class="userinfo-name">
        {{ userinfo.nickname ? userinfo.nickname : '酸谈社群' }}
      </div>
      <img src="~assets/images/public/vip1.png" v-if="vipimg == 'vip1'" class="vipimg">
      <img src="~assets/images/public/captain.png" v-if="captain"  class="captain" />
      <img src="~assets/images/public/captain-s.png" v-if="captain_s"  class="captain-s" />
      <img :src="labelimg" v-if="labelimg" class="labelimg">
      <img src="~assets/images/public/36ce.png" v-else class="labelimg">
    </div>
  </div>

</template>

<script>
export default {
  name: "userInfo",
  props: ['wechat','labelimg','vipimg','captain','captain_s'],
  data() {
    return {
      userinfo:{}
    }
  },
  created() {
    if (this.wechat) {
      this.userinfo = this.wechat
    }
  },
  methods: {

  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;
.userinfo{
  display: flex;
  .userinfo-img{
    width: 50/$r;
    height: 50/$r;
    box-sizing: border-box;
    border: 1px solid #EBEFF2;
    border-radius: 50%;
    margin-right: 15/$r;
  }
  .userinfo-name{
    font-size: 28/$r;
    font-family: PingFang SC;
    font-weight: 400;
    color: #313A43;
  }
  .labelimg{
      width: 84/$r;
    height: 26/$r;
    margin-left: 10/$r;
  }
  .vipimg{
    width: 28/$r;
    height: 25/$r;
    margin-left: 7/$r;
  }
  .captain{
    width: 77/$r;
    height: 29/$r;
    margin-left: 7/$r;
  }
  .captain-s{
    width: 91/$r;
    height: 26/$r;
    margin-left: 7/$r;
  }
}

</style>